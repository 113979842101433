<template>
  <div id="gallery" :key="path" :style="autoStyle">
    <Header :backgroundColor="menuColor" />
    <div class="gallery-container">
      <div class="gallery-title">
        {{ title }}
      </div>
      <div class="item-container">
        <div v-if="path == '/dh/index'" class="dh-class">敬请期待</div>
        <div v-else>
          <div
            class="gallery-item"
            v-for="(item, index) in galleryList"
            :key="item.id"
            @click="gotoDetails(index)"
          >
            <div
              class="gallery-item-left"
              :style="'background-image: url(' + item.imgurl + ')'"
            ></div>
            <div class="gallery-item-right">
              {{ item.outTitle }}
            </div>
          </div>
          <p>已加载全部</p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
  import { getList } from "@/network/index";
  import Header from "./components/Header.vue";
  import Footer from "./components/Footer.vue";
  export default {
    name: "Gallery",
    components: { Header, Footer },
    data() {
      return {
        screenWidth: document.documentElement.clientWidth,
        footerHeight: 0,
        menuColor: "rgb(3, 105, 179)",
        path: this.$route.path,
        galleryList: [],
      };
    },
    created() {
      this.init();
    },
    mounted() {
      window.onresize = () => {
        return (() => {
          this.screenWidth = document.body.clientWidth;
        })();
      };
      this.footerHeight = this.screenWidth * 0.135 + 20;
    },
    watch: {
      $route(to, from) {
        if (to.fullPath != from.fullPath) {
          // console.log(to.path);
          this.path = to.path;
          this.init();
        }
      },
    },
    computed: {
      title() {
        switch (this.path) {
          case "/hd/index":
            return "大学生电影周";
          case "/hd/hgs":
            return "电影好故事";
          case "/hd/px":
            return "十大浙产影片评选";
          case "/zjr/index":
            return "电影 • 浙江日";
          case "/dh/index":
            return "对话";
          default:
            return "";
        }
      },
      autoStyle() {
        return "height:calc(100% - " + this.footerHeight + "px); ";
      },
    },
    methods: {
      init() {
        if (this.path == "/dh/index") return;
        this.getListData();
      },
      // 网络请求
      getListData() {
        let type = "";
        console.log(this.path);
        switch (this.path) {
          case "/hd/index":
            type = "活动";
            break;
          case "/hd/hgs":
            type = "活动-电影好故事";
            break;
          case "/hd/px":
            type = "活动-十大浙产影片评选";
            break;
          case "/zjr/index":
            type = "中国电影 • 浙江日";
            break;
        }
        let data = { category: type };
        getList(data).then((res) => {
          let result = res.result;
          // 图片处理
          result.forEach((item) => {
            if (item.imgurl.length > 0) {
              item.imgurl = item.imgurl[0].url;
            } else {
              item.imgurl = "";
            }
          });
          this.galleryList = result;
        });
      },
      gotoDetails(index) {
        const item = this.galleryList[index];
        if (item != "") {
          if (item.ifout == true) {
            const url = item.url;
            if (url == null || url == "") {
              console.log("未填写链接");
              return;
            }
            const path = url.split("dyz.zjfilm.cn/#")[1];
            if (!path) {
              console.log(url);
              window.location.href = url;
              return;
            }
            let newUrl = window.location.href.split("#")[0] + "#" + path;
            if (newUrl.indexOf("?") != -1) {
              newUrl = newUrl + "&type=" + item.category + "&auId=" + item.id;
            }
            console.log(newUrl);
            window.location.href = newUrl;
          } else {
            this.$router.push({
              path: "/news",
              query: {
                // part: "news_details",
                id: item.id,
                type: item.category,
                auId: item.id,
              },
            });
          }
        }
      },
    },
  };
</script>
<style scoped>
  #gallery {
    position: absolute;
    top: 0;
  }
  .gallery-container {
    position: relative;
    padding-top: 50px !important;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    background-color: #fff;
  }
  .item-container {
    position: relative;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
  }
  .item-container p {
    padding: 10px 0;
    text-align: center;
    color: #72777b;
  }
  .gallery-title {
    position: relative;
    width: 100%;
    font-size: 24px;
    box-sizing: border-box;
    padding: 22px 16px 22px 16px;
    font-weight: bold;
    color: #fff;
    background-image: url("../assets/images/index/bg1.png");
    background-position: top;
    background-repeat: no-repeat;
    font-family: "zt";
    letter-spacing: 1px;
    /* text-align: center; */
  }
  .gallery-item {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 10px 0;
    border-bottom: 2px solid #eee;
  }
  .gallery-item-left {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 30%;
    height: 0;
    padding-bottom: 22%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .gallery-item-right {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 70%;
    font-size: 14px;
    line-height: 24px;
    box-sizing: border-box;
    padding: 0 10px;
  }
  .dh-class {
    position: relative;
    font-size: 32px;
    color: #bebebe;
    text-align: center;
    writing-mode: vertical-lr;
    letter-spacing: 16px;
    margin: 25% auto;
  }
</style>
