<template>
  <div id="footer-container">
    <div class="footer-logo">
      <img src="@/assets/images/index/dblogo.png" />
    </div>
    <div class="footer-text-list">
      <img src="@/assets/images/index/a4.png" alt="" />
    </div>
    <div class="footer-text-code">
      <img src="@/assets/images/index/gzh.jpg" />
      <div>关注微信公众号</div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "Footer",
  };
</script>
<style scoped>
  #footer-container {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    /* background-color: rgba(0, 137, 236, 0.8); */
    background-image: url(../../assets/images/index/footer.png);
    background-size: 100% 100%;

    font-size: 0;
    text-align: center;
  }
  #footer-container > div {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    color: #fff;
  }
  .footer-logo {
    width: 27%;
    text-align: center;
    padding: 10px 0;
  }
  .footer-logo img {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  .footer-text-list {
    width: 45%;
    text-align: left;
    margin-left: 15px;
    white-space: nowrap;
    padding: 10px 0;
  }
  .footer-text-list img {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  .footer-text-code {
    width: 12%;
    text-align: left;
    margin-left: 15px;
    white-space: nowrap;
    padding: 10px 0;
  }
  .footer-text-code img {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  .footer-text-code div {
    position: absolute;
    font-size: 12px;
    height: 12px;
    width: 300px;
    text-align: center;
    transform: scale(0.52);
    -webkit-transform: scale(0.52);
    transform-origin: center center;
    left: 50%;
    margin-left: -150px;
  }
</style>
